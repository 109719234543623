import { Rating } from 'react-simple-star-rating';

const StarsComponent = (props: any) => {
  const ratin = props?.data;

  const rate = ratin || 0;
  return (
    <div key={Math.random()} className="rating-stars">
      <Rating
        initialValue={rate}
        readonly={true}
        size={18}
        allowFraction={true}
      />
    </div>
  );
};

export default StarsComponent;
